import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/SEO";
import Header from "../components/layout/Header.js";
import Title from "../components/layout/Title.js";
import SiteTitleQuery from "../components/SiteTitleQuery";

function corporateSocialResponsibility() {
  return (
    <SiteTitleQuery
      render={data => (
        <Layout title={data.site.siteMetadata.title}>
          <SEO
            title="Samhällsengagemang"
            keywords={[`csr`, `hållbarhet`, `sponsring`, `samhällsengagemang`,]}
          />
          <Header data={data} />
          <Title title="Samhällsengagemang" />

          <div className="h-screen">


          </div>
        </Layout>
      )}
    />
  );
}

export default corporateSocialResponsibility;
